import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export const OldShellTemplate = ({children}) => (
  <>
    <div style={{backgroundColor: '#1F1F1F', position: 'fixed', height: '100vh', width: '100vw', zIndex: '-10'}}/>
    <Navbar/>
    {children}
    <Footer/>
  </>
);
