import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom'

import {AppProvider} from './providers';
import {ThemeProvider} from './theme';
import Routes from './routes';

const App = () => (
  <ThemeProvider>
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>
    </Router>
  </ThemeProvider>
);

export default App;
