import axios from 'axios';
import {AuthService} from '../services';
import {useHttp} from 'some-react-hooks';

export const useApi = () => {
  const axiosInstance = axios.create({
    baseURL: '/api'
  });

  axiosInstance.interceptors.request.use(config => {
    config.headers.authorization = AuthService.getToken();

    return config;
  });

  return useHttp({axiosInstance});
}