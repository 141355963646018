import axios from 'axios';

const TOKEN_FIELD_NAME = 'token';

const setToken = token =>
  localStorage.setItem(TOKEN_FIELD_NAME, JSON.stringify(token));

const removeToken = () =>
  localStorage.removeItem(TOKEN_FIELD_NAME);

export const getToken = () => {
  const rawToken = localStorage.getItem(TOKEN_FIELD_NAME);

  return rawToken ? JSON.parse(rawToken) : null;
}

export const forwardToLogin = () =>
  window.location.href = `/auth/login?redirect=${window.location.pathname}${window.location.search}`;

export const login = loginCode =>
  exchangeLoginCode(loginCode)
    .then(({data}) => setToken(data));

export const logout = () => {
  removeToken();

  return Promise.resolve();
}

const exchangeLoginCode = loginCode =>
  axios.post('/auth/login', {code: loginCode});