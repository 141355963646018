import React, {createContext, useEffect, useState} from 'react';
import {useWindowSize} from '../hooks';

export const MobileContext = createContext(false);

export const MobileProvider = ({children}) => {
  const windowSize = useWindowSize();
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const newIsMobile = windowSize.width <= 768;
    if (newIsMobile !== isMobile) {
      setMobile(newIsMobile);
    }
  }, [windowSize]);

  return (
    <MobileContext.Provider value={isMobile}>
      {children}
    </MobileContext.Provider>
  );
}