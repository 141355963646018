import React, {useState} from 'react'
import clsx from 'clsx';
import {Login} from './login';
import Logo from '../../img/Logo.svg'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    minHeight: 'unset'
  },
  brand: {
    minHeight: 'unset'
  }
});

const Navbar = () => {
  const classes = useStyles();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () =>
    setMenuOpen(x => !x);

  return (
    <nav className={clsx(['navbar', 'is-dark', classes.root])} role="navigation" aria-label="main navigation">
      <div className={clsx(['navbar-brand', classes.brand])}>
        <Link className="navbar-item logo" to="/">
          <img src={Logo} alt="Livida"/>
        </Link>

        <a role="button" className={`navbar-burger ${isMenuOpen ? 'is-active' : ''}`} onClick={toggleMenu}
           aria-label="menu" aria-expanded="false" data-target="navMenu" style={{color: 'white'}}>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
        </a>
      </div>
      <div id="navMenu" className={`navbar-menu is-dark ${isMenuOpen ? 'is-active' : ''}`}
           style={{backgroundColor: '#1C1C1C'}}>
        <div className="navbar-end">
          <Link className="navbar-item has-text-white" to="/tv">TV</Link>
          <Login/>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
