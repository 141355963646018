import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import Loading from './Loading';
import {OldShellTemplate} from '../templates';

const Radio = lazy(() => import('./Radio'));
const TV = lazy(() => import('./TV'));
const NotFound = lazy(() => import('./404'));

const Routes = () => (
  <OldShellTemplate>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route component={Radio} path="/" exact />
        <Route component={TV} path="/tv" exact />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </OldShellTemplate>
);

export default Routes;
