import React from 'react'
import {useMobile} from '../../hooks';

const Footer = () => {
  const isMobile = useMobile();

  return (
    <nav className="footer level has-text-white is-mobile"
         style={{position: isMobile ? window.location.pathname === '/radio' ? '' : 'unset' : ''}}>
      <div className="level-left">
        <p className={`level-item ${isMobile ? 'mobile-footer-text' : ''}`}>&copy; 2020 Livida</p>
      </div>
      <div className="level-item"
           style={{position: 'absolute', left: '0', right: '0', margin: '0 auto', textAlign: 'center'}}>
        <a href="https://twitter.com/LividaST">
          <i className={`fab fa-twitter ${isMobile ? 'fa-1x' : 'fa-2x'}`}
             style={{marginRight: '0.5vw', color: 'white'}}/>
        </a>
        <a href="https://discord.gg/6vRE5NJ">
          <i className={`fab fa-discord ${isMobile ? 'fa-1x' : 'fa-2x'}`}
             style={{marginLeft: '0.5vw', color: 'white'}}/>
        </a>
      </div>
    </nav>
  );
}

export default Footer;