import React, {createContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import queryString from 'query-string';
import {useApi} from '../hooks';
import {Loading} from '../components/loading';
import {AuthService} from '../services';

export const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();
  const api = useApi();

  const fetchUser = () => api.get('/users/me');

  const login = loginCode => AuthService.login(loginCode)

  const getLoginCode = () => {
    const {loginCode} = queryString.parse(history.location.search);

    return loginCode;
  }

  const removeLoginCode = () => {
    const qs = queryString.parse(location.search);

    delete qs.loginCode;

    history.replace({
      search: queryString.stringify(qs)
    });
  }

  const initialize = async () => {
    const loginCode = getLoginCode();

    if (loginCode) {
      try {
        await login(loginCode)
      } finally {
        removeLoginCode();
      }
    }

    const user = await fetchUser();

    setUser(user);
  }

  useEffect(() => {
    initialize()
      .finally(() => setLoading(false));
  }, []);

  if (isLoading) {
    return <Loading/>;
  }

  const logout = () =>
    AuthService.logout()
      .then(() => setUser(null));

  return (
    <AuthContext.Provider value={{user, logout}}>
      {children}
    </AuthContext.Provider>
  );
}
