import React from 'react';
import {Button, makeStyles} from '@material-ui/core';
import {AuthService} from '../../../services';
import {useAuth} from '../../../hooks';
import {UserAvatar} from './UserAvatar';

const useStyles = makeStyles(theme => ({
  button: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
    color: 'white !important',
    borderRadius: 0
  }
}));

export const Login = () => {
  const classes = useStyles();
  const {user} = useAuth();

  return (
    <>
      {
          <Button className={classes.button} variant="contained" color="primary" onClick="#">Login</Button>
      }
    </>
  );
};
