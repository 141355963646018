import React from 'react';
import {createMuiTheme, ThemeProvider as MaterialThemeProvider} from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#8000FF'
    },
    text: {
      primary: '#FFFFFF'
    }
  },
  overrides: {
    MuiIconButton: {
      colorPrimary: {
        color: '#FFFFFF'
      }
    }
  }
});

export const ThemeProvider = ({children}) => (
  <MaterialThemeProvider theme={theme}>
    {children}
  </MaterialThemeProvider>
);
