import React from 'react';
import {Box, CircularProgress} from '@material-ui/core';

const Loading = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress size={50}/>
  </Box>
);

export default Loading;
